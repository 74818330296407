import { Fragment, useState, useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import { Dialog, Transition, Disclosure } from "@headlessui/react";
import {
  HomeIcon,
  XIcon,
  CashIcon,
  CogIcon,
  LogoutIcon,
  CurrencyDollarIcon,
  FolderOpenIcon,
  UserGroupIcon,
  HeartIcon,
} from "@heroicons/react/outline";
import { MenuAlt4Icon } from "@heroicons/react/solid";
import { useRouter } from "next/router";
import { classNames } from "../utils/classNames";
import { Can } from "../utils/casl/abilityContext";
import { PalmaLogo } from "./logo";

type NavigationOption = {
  name: string;
  href: string;
  Icon?: (props: any) => JSX.Element;
  children?: NavigationOption[];
  child?: boolean;
};

const navigationOptions: NavigationOption[] = [
  // { name: "Dashboard", href: "/dashboard", Icon: HomeIcon },
  { name: "Team", href: "/members", Icon: UserGroupIcon },
  { name: "Paystubs", href: "/paystubs", Icon: CashIcon },
  { name: "Labor Benefit Fund", href: "/lbf", Icon: CurrencyDollarIcon }
];

const adminNavigationOptions: NavigationOption[] = [
  {
    name: "Payroll",
    href: "/payroll",
    Icon: CurrencyDollarIcon,
    children: [
      { name: "Run Payroll", href: "/payroll", child: true },
      { name: "Payroll History", href: "/payroll/history", child: true },
      { name: "Payroll Settings", href: "/payroll/settings", child: true },
    ],
  },
  {
    name: "Benefits",
    href: "/benefits",
    Icon: HeartIcon,
    children: [
      { name: "Manage Labor Benefits", href: "/benefits/lbf/manage", child: true },
    ],
  },
  { name: "Contracts", href: "/contracts", Icon: FolderOpenIcon },
  { name: "Settings", href: "/settings/company", Icon: CogIcon },
];

export const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const router = useRouter();

  let closeButtonRef = useRef(null);

  const isOptionSelected = ({ href, child }: NavigationOption) => {
    return child ? router.pathname == href : router.pathname.startsWith(href);
  };
  return (
    <div className="lg:h-screen sticky top-0">
      {/* Sidebar for mobile */}
      <div className="flex lg:hidden pl-2 pt-2 sm:pl-3 sm:pt-3">
        <button
          className="z-50 bg-white shadow-sm -ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt4Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 lg:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
          initialFocus={closeButtonRef}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                    ref={closeButtonRef}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <PalmaLogo size="sm" redirectToDashboard />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-8">
                  <div className="space-y-1">
                    {navigationOptions.map((navigationOption) => (
                      <MobileNavigationOption
                        {...{ navigationOption, isOptionSelected }}
                      />
                    ))}
                  </div>
                  <Can I="view" a="admin">
                    <div className="space-y-1">
                      <h3
                        className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                        id="projects-headline"
                      >
                        Admin
                      </h3>
                      {adminNavigationOptions.map((navigationOption) => (
                        <MobileNavigationOption
                          {...{ navigationOption, isOptionSelected }}
                        />
                      ))}
                    </div>
                  </Can>
                </nav>
              </div>
              {/* <div className="flex-shrink-0 mt-auto flex border-t border-gray-200 p-4">
                <a href="#" className="flex-shrink-0 w-full group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-md font-medium text-gray-700 group-hover:text-gray-900">
                        Tom Cook
                      </p>
                      <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                        View profile
                      </p>
                    </div>
                  </div>
                </a>
              </div>  */}
              <Link href="/api/auth/logout">
                <a className="border-t mt-2 w-10/12 self-center flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-semibold text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">
                  <LogoutIcon className="h-5 w-5 mr-1.5" aria-hidden="true" />
                  Logout
                </a>
              </Link>
            </div>
          </Transition.Child>

          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      {/* Sidebar for desktop */}
      <div className="hidden h-full lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-100 space-y-4">
          <div className="flex items-center flex-shrink-0 px-4">
            <PalmaLogo size="sm" redirectToDashboard />
          </div>
          <div className="h-0 flex-1 flex flex-col overflow-y-auto">
            {/* Navigation */}
            <nav className="px-3 space-y-8">
              <div className="space-y-1">
                {navigationOptions.map((navigationOption) => (
                  <DesktopNavigationOption
                    {...{ navigationOption, isOptionSelected }}
                  />
                ))}
              </div>
              <Can I="view" a="admin">
                <div className="space-y-1">
                  <h3
                    className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                    id="projects-headline"
                  >
                    Admin
                  </h3>
                  {adminNavigationOptions.map((navigationOption) => (
                    <DesktopNavigationOption
                      {...{ navigationOption, isOptionSelected }}
                    />
                  ))}
                </div>
              </Can>
            </nav>
            <div className="flex-shrink-0 mt-auto flex flex-col border-t border-gray-200 p-4">
              {/* <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      Tom Cook
                    </p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      View profile
                    </p>
                  </div>
                </div>
              </a> */}
              <Link href="/api/auth/logout">
                <a className="mt-2 w-10/12 self-center flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-xs font-semibold text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">
                  <LogoutIcon className="h-4 w-4 mr-1.5" aria-hidden="true" />
                  Logout
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type NavigationOptionProps = {
  navigationOption: NavigationOption;
  isOptionSelected: (nav: NavigationOption) => boolean;
};

const DesktopNavigationOption = ({
  navigationOption,
  isOptionSelected,
}: NavigationOptionProps) => {
  const { name, href, Icon, children } = navigationOption;
  const isSelected = isOptionSelected(navigationOption);
  return (
    <>
      {!children ? (
        <Link href={href} key={name}>
          <a
            key={name}
            href={href}
            className={classNames(
              isSelected
                ? "bg-primary-100 text-primary-700 font-medium"
                : "text-gray-700 hover:text-gray-900 hover:bg-gray-200",
              "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
            )}
            aria-current={
              isOptionSelected(navigationOption) ? "page" : undefined
            }
          >
            <Icon
              className={classNames(
                isSelected
                  ? "text-primary-700"
                  : "text-gray-400 group-hover:text-gray-500",
                "mr-2 flex-shrink-0 h-6 w-6"
              )}
              aria-hidden="true"
            />
            {name}
          </a>
        </Link>
      ) : (
        <Disclosure as="div" key={name} className="space-y-1">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={classNames(
                  isSelected
                    ? "bg-primary-100 text-primary-700 font-medium"
                    : "text-gray-700 hover:text-gray-900 hover:bg-gray-200",
                  "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none"
                )}
              >
                <Icon
                  className={classNames(
                    isSelected
                      ? "text-primary-700"
                      : "text-gray-400 group-hover:text-gray-500",
                    "mr-2 flex-shrink-0 h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                <span className="flex-1">{name}</span>
                <svg
                  className={classNames(
                    isSelected &&
                      "text-primary-500 group-hover:text-primary-600",
                    open ? "text-gray-400 rotate-90" : "text-gray-300",
                    "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                  )}
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                </svg>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="space-y-1">
                  {children.map((subItem) => (
                    <Disclosure.Button key={subItem.name} className="w-full">
                      <Link href={subItem.href} key={subItem.name}>
                        <a
                          key={subItem.name}
                          href={subItem.href}
                          className={classNames(
                            isOptionSelected(subItem)
                              ? "bg-primary-50 text-primary-700 hover:text-primary-800"
                              : "text-gray-600 hover:text-gray-900 hover:bg-gray-200",
                            "group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium  rounded-md "
                          )}
                          aria-current={
                            isOptionSelected(subItem) ? "page" : undefined
                          }
                        >
                          {subItem.name}
                        </a>
                      </Link>
                    </Disclosure.Button>
                  ))}
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      )}
    </>
  );
};

const MobileNavigationOption = ({
  navigationOption,
  isOptionSelected,
}: NavigationOptionProps) => {
  const { name, href, Icon, children } = navigationOption;
  const isSelected = isOptionSelected(navigationOption);
  return (
    <>
      {!children ? (
        <Link href={href} key={name}>
          <a
            key={name}
            href={href}
            className={classNames(
              isSelected
                ? "bg-primary-100 text-primary-700 font-semibold"
                : "text-gray-600 hover:text-gray-900 hover:bg-gray-50",
              "group flex items-center px-2 py-3 text-md leading-5 font-medium rounded-md"
            )}
            aria-current={
              isOptionSelected(navigationOption) ? "page" : undefined
            }
          >
            <Icon
              className={classNames(
                isSelected
                  ? "text-primary-700"
                  : "text-gray-400 group-hover:text-gray-500",
                "mr-3 flex-shrink-0 h-6 w-6"
              )}
              aria-hidden="true"
            />
            {name}
          </a>
        </Link>
      ) : (
        <Disclosure as="div" key={name} className="space-y-1">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={classNames(
                  isSelected
                    ? "bg-primary-100 text-primary-700 font-semibold"
                    : "text-gray-600 hover:text-gray-900 hover:bg-gray-50",
                  "group w-full flex items-center px-2 py-3 text-left text-md leading-5 font-medium rounded-md focus:outline-none"
                )}
              >
                <Icon
                  className={classNames(
                    isSelected
                      ? "text-primary-700"
                      : "text-gray-400 group-hover:text-gray-500",
                    "mr-3 flex-shrink-0 h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                <span className="flex-1">{name}</span>
                <svg
                  className={classNames(
                    isSelected &&
                      "text-primary-500 group-hover:text-primary-600",
                    open ? "text-gray-400 rotate-90" : "text-gray-300",
                    "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                  )}
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                </svg>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="space-y-1">
                  {children.map((subItem) => (
                    <Disclosure.Button key={subItem.name} className="w-full">
                      <Link href={subItem.href} key={subItem.name}>
                        <a
                          key={subItem.name}
                          href={subItem.href}
                          className={classNames(
                            isOptionSelected(subItem)
                              ? "bg-primary-50 text-primary-700 hover:text-primary-800"
                              : "text-gray-600 hover:text-gray-900 hover:bg-gray-200",
                            "group w-full flex items-center pl-11 pr-2 py-3 text-md font-medium  rounded-md "
                          )}
                          aria-current={
                            isOptionSelected(subItem) ? "page" : undefined
                          }
                        >
                          {subItem.name}
                        </a>
                      </Link>
                    </Disclosure.Button>
                  ))}
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      )}
    </>
  );
};
